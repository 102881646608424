import {
    Box,
    BoxProps,
    type CompoundStylesApiProps,
    Container,
    factory,
    type Factory,
    useProps,
} from '@components/mantine';
import {forwardRef, type ForwardRefExoticComponent, type ReactNode, type RefAttributes} from 'react';
import {usePageContext} from '../../contexts';
import {type PageVariant} from './Page';

export type PageHeaderStylesNames = 'header' | 'headerContainer';

export interface PageHeaderProps extends BoxProps, CompoundStylesApiProps<PageHeaderFactory> {
    children: ReactNode;
}

export type PageHeaderFactory = Factory<{
    props: PageHeaderProps;
    ref: HTMLDivElement;
    stylesNames: PageHeaderStylesNames;
    compound: true;
}>;

const defaultProps: Partial<PageHeaderProps> = {};

const FullPageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(({children, ...others}, ref) => (
    <Box ref={ref} {...others}>
        {children}
    </Box>
));

const CenteredPageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(({children, ...others}, ref) => {
    const {getStyles} = usePageContext();
    return (
        <Box ref={ref} {...others}>
            <Container w="100%" {...getStyles('headerContainer')}>
                {children}
            </Container>
        </Box>
    );
});

const pageHeaderVariants: Record<
    PageVariant,
    ForwardRefExoticComponent<PageHeaderProps & RefAttributes<HTMLDivElement>>
> = {
    full: FullPageHeader,
    centered: CenteredPageHeader,
};

export const PageHeader = factory<PageHeaderFactory>((props, ref) => {
    const {variant, getStyles, isEmpty, isLoading} = usePageContext();
    const {style, styles, className, classNames, ...others} = useProps('PageHeader', defaultProps, props);
    const PageHeaderComponent = pageHeaderVariants[variant] ?? FullPageHeader;

    return !isEmpty && !isLoading ? (
        <PageHeaderComponent ref={ref} {...getStyles('header', {style, styles, className, classNames})} {...others} />
    ) : null;
});
