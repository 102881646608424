import {API, Resource} from '@coveo/platform-client';
import {
    CatalogFieldsResponseModel,
    CatalogFieldValuesRequestModel,
    CatalogFieldValuesResponseModel,
} from '../../interfaces/commerce/CatalogInterfaces';

export class Catalog extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/catalog`;

    /**
     * Returns the possible values of a field in the catalog source.
     * @param catalogId string
     * @param options CatalogFieldValuesRequestModel
     * @returns CatalogFieldValuesResponseModel
     */
    getFieldValues(catalogId: string, options: CatalogFieldValuesRequestModel) {
        return this.api.post<CatalogFieldValuesResponseModel>(`${Catalog.baseUrl}/${catalogId}/field/values`, options);
    }

    getFields(catalogId: string) {
        return this.api.post<CatalogFieldsResponseModel>(`${Catalog.baseUrl}/${catalogId}/fields`);
    }
}
