import {createSafeContext, type GetStylesApi} from '@components/mantine';
import {Dispatch, SetStateAction} from 'react';
import {type PageFactory, type PageVariant} from '../components';

export interface PageContextValue {
    variant: PageVariant;
    getStyles: GetStylesApi<PageFactory>;
    pageTitle: string;
    setPageTitle: Dispatch<SetStateAction<string>>;
    isEmpty: boolean;
    setIsEmpty: Dispatch<SetStateAction<boolean>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const [PageProvider, usePageContext] = createSafeContext<PageContextValue>(
    'Page component was not found in the tree',
);
