import {FunctionComponent, useEffect} from 'react';
import {NavigateProps} from 'react-router';

import {useNavigate} from '../hooks';

export const Navigate: FunctionComponent<NavigateProps & {injectOrg?: boolean}> = ({
    to,
    replace,
    state,
    injectOrg = true,
}) => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(to, {replace, state, injectOrg});
    }, [to, replace, state, injectOrg]);

    return null;
};
