import {DateTimeFormatter} from '@core/format';
import {
    EllipsisText,
    factory,
    Factory,
    Stack,
    StackProps,
    StylesApiProps,
    Text,
    useProps,
    useStyles,
} from '@coveord/plasma-mantine';
import dayjs from 'dayjs';
import {ReactNode} from 'react';
import classes from './LastModificationCell.module.css';

export type LastModificationCellStylesNames = 'root' | 'email' | 'emailText' | 'lastModified';
export type LastModificationCellFactory = Factory<{
    props: LastModificationCellProps;
    ref: HTMLDivElement;
    stylesNames: LastModificationCellStylesNames;
}>;

export interface LastModificationCellProps
    extends Omit<StackProps, 'classNames' | 'styles' | 'vars'>,
        StylesApiProps<LastModificationCellFactory> {
    lastModified: dayjs.ConfigType;
    email?: string;
    renderEmail?: (props: {children?: ReactNode; email?: string}) => ReactNode;
}

const defaultProps: Partial<LastModificationCellProps> = {
    renderEmail: ({children}) => children,
};

export const LastModificationCell = factory<LastModificationCellFactory>((_props, ref) => {
    const props = useProps('LastModificationCell', defaultProps, _props);
    const {classNames, styles, style, className, vars, email, renderEmail, lastModified, ...others} = props;
    const getStyles = useStyles<LastModificationCellFactory>({
        name: 'LastModificationCell',
        classes,
        vars,
        classNames,
        className,
        style,
        styles,
        props,
    });

    return (
        <Stack ref={ref} gap={0} {...getStyles('root', {style, styles, className, classNames})} {...others}>
            {email
                ? renderEmail({
                      children: (
                          <EllipsisText
                              classNames={{text: classes.emailText}}
                              {...getStyles('email', {styles, classNames})}
                          >
                              {email}
                          </EllipsisText>
                      ),
                      email,
                  })
                : null}
            <Text {...getStyles('lastModified', {styles, classNames})}>
                {DateTimeFormatter.calendar.format(lastModified)}
            </Text>
        </Stack>
    );
});
