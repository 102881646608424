import {
    type CompoundStylesApiProps,
    Container,
    factory,
    type Factory,
    Flex,
    FlexProps,
    useProps,
} from '@components/mantine';
import {forwardRef, type ForwardRefExoticComponent, type ReactNode, type RefAttributes} from 'react';
import {usePageContext} from '../../contexts';
import {type PageVariant} from './Page';

export type PageBodyStylesNames = 'body' | 'bodyContainer';

export interface PageBodyProps
    extends Omit<FlexProps, 'classNames' | 'styles' | 'vars'>,
        CompoundStylesApiProps<PageBodyFactory> {
    children: ReactNode;
}

export type PageBodyFactory = Factory<{
    props: PageBodyProps;
    ref: HTMLDivElement;
    stylesNames: PageBodyStylesNames;
    compound: true;
}>;

const defaultProps: Partial<PageBodyProps> = {};

type PageBodyComponentProps = Omit<PageBodyProps, 'className' | 'style' | 'classNames' | 'styles' | 'vars'>;

const FullPageBody = forwardRef<HTMLDivElement, PageBodyComponentProps>((props, ref) => (
    <Flex ref={ref} flex={1} {...props} />
));

const CenteredPageBody = forwardRef<HTMLDivElement, PageBodyComponentProps>(({children, ...others}, ref) => {
    const {getStyles} = usePageContext();
    return (
        <Flex ref={ref} flex={1} {...others}>
            <Container w="100%" {...getStyles('bodyContainer')}>
                {children}
            </Container>
        </Flex>
    );
});

const pageBodyVariants: Record<
    PageVariant,
    ForwardRefExoticComponent<PageBodyProps & RefAttributes<HTMLDivElement>>
> = {
    full: FullPageBody,
    centered: CenteredPageBody,
};

export const InnerPageBody = factory<PageBodyFactory>((_props, ref) => {
    const {variant, getStyles} = usePageContext();
    const {className, style, classNames, styles, vars: _vars, ...others} = useProps('PageBody', defaultProps, _props);
    const PageBodyComponent = pageBodyVariants[variant] ?? FullPageBody;

    return <PageBodyComponent ref={ref} {...others} {...getStyles('body', {className, style, classNames, styles})} />;
});

export const PageBody = forwardRef<HTMLDivElement, PageBodyProps>((props, ref) => {
    const {isEmpty, isLoading} = usePageContext();
    return !isEmpty && !isLoading ? <InnerPageBody ref={ref} {...props} /> : null;
});
