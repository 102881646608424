import {OwnedProps, useOwnership} from '@core/debug';
import {
    factory,
    Factory,
    Modal as MantineModal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalCssVariables,
    ModalHeader,
    ModalOverlay,
    ModalProps as MantineModalProps,
    ModalRootProps as MantineModalRootProps,
    ModalStylesNames,
    ModalTitle,
} from '@mantine/core';
import {StickyFooter, StickyFooterFactory} from '@coveord/plasma-mantine';

export type ModalProps = MantineModalProps & OwnedProps;

export type ModalRootProps = MantineModalRootProps & OwnedProps;

// Need to redeclare the factory to override the props type
export type ModalFactory = Factory<{
    props: ModalProps;
    ref: HTMLDivElement;
    stylesNames: ModalStylesNames;
    vars: ModalCssVariables;
    staticComponents: {
        Root: typeof ModalRoot;
        Overlay: typeof ModalOverlay;
        Content: typeof ModalContent;
        Body: typeof ModalBody;
        Header: typeof ModalHeader;
        Title: typeof ModalTitle;
        CloseButton: typeof ModalCloseButton;
        Footer: typeof ModalStickyFooter;
    };
}>;
export const Modal = factory<ModalFactory>(({framework, owner, ...props}: ModalProps, ref) => {
    useOwnership(owner, framework, !props.opened);
    return <MantineModal ref={ref} {...props} />;
});

// Need to redeclare the factory to override the props type
export type ModalRootFactory = Factory<{
    props: ModalRootProps;
    ref: HTMLDivElement;
    stylesNames: ModalStylesNames; // Mantine doesn't export ModalRootStylesNames, but it's the same as ModalStylesNames
    vars: ModalCssVariables; // Mantine doesn't export ModalRootCssVariables, but it's the same as ModalCssVariables
    compound: true;
}>;
const ModalRoot = factory<ModalRootFactory>(({framework, owner, ...props}: ModalRootProps, ref) => {
    useOwnership(owner, framework, !props.opened);
    return <MantineModal.Root ref={ref} {...props} />;
});

const ModalStickyFooter = factory<StickyFooterFactory>((props, ref) => (
    <StickyFooter ref={ref} {...props} variant="modal-footer" />
));

Modal.Root = ModalRoot;
Modal.Body = MantineModal.Body;
Modal.Overlay = MantineModal.Overlay;
Modal.Content = MantineModal.Content;
Modal.Header = MantineModal.Header;
Modal.Title = MantineModal.Title;
Modal.CloseButton = MantineModal.CloseButton;
Modal.Footer = ModalStickyFooter;
