import {Box, BoxProps, createPolymorphicComponent, Tabs, TabsListProps} from '@components/mantine';
import {Navigate, Outlet, Route, Routes, useMatch, useNavigate, useResolvedPath} from '@core/routes';
import {
    Children,
    ComponentProps,
    createContext,
    forwardRef,
    FunctionComponent,
    ReactElement,
    ReactNode,
    useContext,
} from 'react';

interface TabsLayoutProps extends BoxProps {
    children: ReactElement[];
}

const TabLayoutContext = createContext<{
    tabs: Array<ReactElement<TabLayoutPanelProps, FunctionComponent<TabLayoutPanelProps>>>;
    activeTab?: string;
}>({tabs: []});

/**
 * @returns The URL identifier of the active tab panel.
 */
export const useTabsLayout = () => {
    const context = useContext(TabLayoutContext);
    if (!context) {
        throw new Error('useTabsLayout must be used within a TabsLayout');
    }

    return context.activeTab;
};

export const TabsLayout = createPolymorphicComponent<
    'div',
    TabsLayoutProps,
    {
        /**
         * This component defines a tab panel within the tab layout.
         * A tab button will be automatically created for each panel when rendering the TabsList.
         */
        Panel: typeof TabLayoutPanel;
        /**
         * This component renders the tabs list (the tab buttons).
         */
        TabsList: typeof TabLayoutTabsList;
    }
>(
    forwardRef<HTMLDivElement, TabsLayoutProps>(({children, ...others}, ref) => {
        const {pathname: basePath} = useResolvedPath('.', {relative: 'route'});
        const convertedChildren = Children.toArray(children) as ReactElement[];
        const otherChildren = convertedChildren.filter((child) => child.type !== TabLayoutPanel);
        const tabs = convertedChildren.filter((child) => child.type === TabLayoutPanel) as Array<
            ReactElement<ComponentProps<typeof TabLayoutPanel>, typeof TabLayoutPanel>
        >;
        const defaultTab = tabs.find((tab) => tab.props.default)?.props.path ?? tabs[0].props.path;
        const activeTab = useMatch(basePath + `/:tab/*`)?.params.tab ?? defaultTab;

        return (
            <TabLayoutContext.Provider value={{tabs, activeTab}}>
                {otherChildren}
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Box ref={ref} {...others}>
                                <Outlet />
                            </Box>
                        }
                    >
                        <Route index element={<Navigate to={`./${defaultTab}`} replace />} />
                        {tabs.map((tab) => (
                            <Route key={tab.props.path} path={`${tab.props.path}/*`} element={tab.props.children} />
                        ))}
                    </Route>
                </Routes>
            </TabLayoutContext.Provider>
        );
    }),
);

interface TabLayoutPanelProps {
    /**
     * URL identifier of the tab panel. It will be used as segment to navigate to it.
     * Must be unique among the tab panels of the same TabsLayout.
     */
    path: string;
    /**
     * Label of the tab panel. It will be used as the text of the tab button.
     */
    label: ReactNode;
    /**
     * Content of the tab panel. It will be rendered when the tab is active.
     */
    children: ReactNode;
    /**
     * If true, this tab panel will be the active one when the tab layout is rendered without a specific tab path.
     * If not provided, the first tab panel will be the default one.
     * If multiple tab panels have the default prop set to true, the first one will be the default one.
     */
    default?: boolean;
}

const TabLayoutPanel: FunctionComponent<TabLayoutPanelProps> = ({children}) => children;
const TabLayoutTabsList: FunctionComponent<Omit<TabsListProps, 'children'>> = (tabsListProps) => {
    const {tabs, activeTab} = useContext(TabLayoutContext);
    const navigate = useNavigate();
    return (
        <Tabs value={activeTab} onChange={(tab) => navigate(`./${tab}`)}>
            <Tabs.List {...tabsListProps}>
                {tabs.map((tab) => (
                    <Tabs.Tab key={tab.props.path} value={tab.props.path}>
                        {tab.props.label}
                    </Tabs.Tab>
                ))}
            </Tabs.List>
        </Tabs>
    );
};

TabsLayout.Panel = TabLayoutPanel;
TabsLayout.TabsList = TabLayoutTabsList;
