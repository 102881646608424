import {
    Box,
    ColumnDef,
    createColumnHelper,
    EmptyState,
    Flex,
    Group,
    Input,
    NavLink,
    Skeleton,
    Stack,
    StatusToken,
    Table,
    Text,
    Tooltip,
    useTable,
} from '@components/mantine';
import {FunctionComponent, useMemo, useState} from 'react';
import {Locales} from '../../strings/Locales';
import {AccessLevelPicker} from './AccessLevelPicker';
import {CanCreateCheckbox} from './CanCreateCheckbox';
import {GranularPrivilegesTable} from './granular-privileges/GranularPrivilegesTable';
import classes from './PrivilegesTable.module.css';
import {type PrivilegeRow, type PrivilegesTableProps} from './PrivilegesTable.types';
import {noAccess} from './PrivilegesTableConstants';
import {PrivilegesTableUtils} from './PrivilegesTableUtils';
import {PrivilegesTableContextProvider} from './PrivilegeTableContext';
import {UndoButton} from './UndoButton';

const columnsHelper = createColumnHelper<PrivilegeRow>();

const columns: Array<ColumnDef<PrivilegeRow>> = [
    columnsHelper.accessor('name', {
        id: 'privilege-name',
        header: Locales.format('PrivilegesTable.column.name.header'),
        enableSorting: false,
    }),
    columnsHelper.display({
        id: 'access-level-picker',
        header: Locales.format('PrivilegesTable.column.access.header'),
        cell: ({row}) => <AccessLevelPicker {...row.original} />,
    }),
    columnsHelper.display({
        id: 'can-create-checkbox',
        header: Locales.format('PrivilegesTable.column.create.header'),
        size: 84,
        cell: ({row}) => <CanCreateCheckbox {...row.original} />,
    }),
    columnsHelper.display({
        id: 'undo-button',
        cell: ({row}) => <UndoButton {...row.original} />,
        size: 84,
    }),
    Table.CollapsibleColumn as ColumnDef<PrivilegeRow>,
];

export const PrivilegesTable: FunctionComponent<PrivilegesTableProps> = ({
    systemPrivileges,
    initialPrivileges,
    selectedPrivileges = noAccess,
    availablePrivileges = noAccess,
    loading = false,
    readOnly = false,
    exclusivePrivileges = noAccess,
    onChange,
    count = 'modified',
    error,
}) => {
    const [customPrivileges, setCustomPrivileges] = useState<string[]>([]);
    const [rows, tabs] = useMemo(
        () => [
            PrivilegesTableUtils.getRowsFromPrivileges({
                systemPrivileges,
                availablePrivileges,
                initialPrivileges,
                exclusivePrivileges,
            }),
            PrivilegesTableUtils.getTabsFromPrivileges(systemPrivileges),
        ],
        [systemPrivileges, availablePrivileges, initialPrivileges, exclusivePrivileges],
    );

    const [activeTab, setActiveTab] = useState<string | null>(tabs.includes('Content') ? 'Content' : tabs[0]);
    const selectedPrivilegesByRowId = PrivilegesTableUtils.groupPrivilegesByRowId(selectedPrivileges);
    const data = useMemo(
        () => rows.filter((row) => row.tab === activeTab).sort((a, b) => a.name.localeCompare(b.name)),
        [rows, activeTab],
    );
    const table = useTable<PrivilegeRow>({initialState: {totalEntries: data.length}});

    return (
        <PrivilegesTableContextProvider
            value={{selectedPrivilegesByRowId, onChange, customPrivileges, setCustomPrivileges, readOnly}}
        >
            <Stack gap="xs">
                {error ? <Input.Error>{error}</Input.Error> : null}
                <Flex>
                    {!table.isVacant ? (
                        <Box
                            w={256}
                            className={classes.navigation}
                            data-testid="privileges-table-navigation"
                            data-loading={loading}
                        >
                            {tabs.map((tab) => {
                                const [totalRowsCount, changedRowsCount, rowHasWarning] = rows.reduce<
                                    [number, number, boolean]
                                >(
                                    (tabInfo, row) => {
                                        if (row.tab === tab) {
                                            let [totalCount, changedCount, hasWarning] = tabInfo;
                                            const selectedPrivilegesForRow =
                                                selectedPrivilegesByRowId[row.id] ?? noAccess;
                                            totalCount += 1;

                                            if (
                                                PrivilegesTableUtils.hasChanged(
                                                    row.initialPrivileges,
                                                    selectedPrivilegesForRow,
                                                )
                                            ) {
                                                changedCount += 1;
                                            }

                                            if (!hasWarning) {
                                                hasWarning =
                                                    PrivilegesTableUtils.getWarnings({
                                                        selectedPrivileges: selectedPrivilegesForRow,
                                                        initialPrivileges: row.initialPrivileges,
                                                        availablePrivileges: row.availablePrivileges,
                                                        exclusivePrivileges: row.exclusivePrivileges,
                                                    }).length > 0;
                                            }
                                            return [totalCount, changedCount, hasWarning];
                                        }
                                        return tabInfo;
                                    },
                                    [0, 0, false],
                                );
                                return (
                                    <Skeleton key={tab} visible={loading} className={classes.skeleton}>
                                        <NavLink
                                            key={tab}
                                            component="button"
                                            label={tab}
                                            active={tab === activeTab}
                                            onClick={() => setActiveTab(tab)}
                                            rightSection={
                                                <Group gap="xs">
                                                    {!readOnly && rowHasWarning ? (
                                                        <StatusToken
                                                            size="sm"
                                                            variant="caution"
                                                            data-testid="warning-indicator"
                                                        />
                                                    ) : null}
                                                    {count === 'modified' && changedRowsCount > 0 ? (
                                                        <Tooltip
                                                            label={Locales.format(
                                                                'PrivilegesTable.changesCount.tooltip',
                                                                {
                                                                    smart_count: changedRowsCount,
                                                                },
                                                            )}
                                                        >
                                                            <Text fz="xs">{`(${changedRowsCount})`}</Text>
                                                        </Tooltip>
                                                    ) : null}
                                                    {count === 'total' && <Text fz="xs">{`(${totalRowsCount})`}</Text>}
                                                </Group>
                                            }
                                        />
                                    </Skeleton>
                                );
                            })}
                        </Box>
                    ) : null}
                    <Table
                        flex={1}
                        store={table}
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={({id}) => id}
                        getRowExpandedContent={GranularPrivilegesTable}
                    >
                        <Table.NoData>
                            <EmptyState variant="feedback">
                                <EmptyState.Title>
                                    {Locales.format('PrivilegesTable.emptyState.title')}
                                </EmptyState.Title>
                                <EmptyState.Image />
                            </EmptyState>
                        </Table.NoData>
                    </Table>
                </Flex>
            </Stack>
        </PrivilegesTableContextProvider>
    );
};
