import {Anchor, Menu, Stack, Status, StatusToken, StatusTokenVariant, Text, useDisclosure} from '@components/mantine';
import {Guard} from '@components/security';
import {SourceStatusTypeWithTransition, useQuery} from '@core/api';
import {Config, STATUS_PAGE_URL} from '@core/configuration';
import {DateTimeFormatter} from '@core/format';
import {LicenseSelectors} from '@core/license';
import {Localization} from '@core/locales';
import {OrganizationSelectors} from '@core/organization';
import {generateDocLink, useNavigate} from '@core/routes';
import {UserSelectors} from '@core/user';
import {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';
import {Locales} from '../../strings';
import {NotificationsTracking} from '../../tracking/NotificationsTracker';
import {NotificationsButton} from './NotificationsButton';
import classes from './NotificationsMenu.module.css';
import {NotificationsMenuUtils} from './NotificationsMenuUtils';
import {StatusActions, StatusConstants, SystemsStatusType} from './status';
import {WhatsNewActions, WhatsNewModal} from './whats-new';

const StatusBackgroundMapping = {
    [SystemsStatusType.critical]: 'error',
    [SystemsStatusType.major]: 'error',
    [SystemsStatusType.minor]: 'caution',
    [SystemsStatusType.none]: 'success',
};

export const NotificationsMenu: FunctionComponent = () => {
    const user = useSelector(UserSelectors.getUser);
    const lastSeenWhatsNew = useSelector(UserSelectors.getAdditionalInformation).LastWhatsNew;
    const {canViewActivities} = useSelector(UserSelectors.getPrivilegesValidator);
    const organizationStatus = useSelector(OrganizationSelectors.getOrganizationStatus);
    const trialExpired = useSelector(LicenseSelectors.isTrialExpired);
    const {data: latestWhatsNew} = useQuery({
        queryKey: ['lastWhatsNew'],
        queryFn: WhatsNewActions.getLatestWhatsNew,
        refetchOnWindowFocus: false,
    });
    const {data: systemsStatus} = useQuery({
        queryKey: ['systemStatus'],
        queryFn: StatusActions.getSystemsStatus,
        refetchOnWindowFocus: false,
    });
    const {data: scheduledMaintenance} = useQuery({
        queryKey: ['scheduledMaintenance'],
        queryFn: StatusActions.getScheduledMaintenance,
        refetchOnWindowFocus: false,
    });

    const hasUnreadNews = latestWhatsNew && latestWhatsNew !== lastSeenWhatsNew;
    const hasUpcomingMaintenance = scheduledMaintenance?.status === 'scheduled';
    const {variant: orgStatusToken, message} = NotificationsMenuUtils.getOrgStatusInfo(organizationStatus);
    const systemsStatusToken = StatusBackgroundMapping[systemsStatus?.indicator] as StatusTokenVariant;

    const navigate = useNavigate();
    const [opened, {open, close}] = useDisclosure(false);

    let statusVariantButton: StatusTokenVariant;

    if (orgStatusToken === 'error' || systemsStatusToken === 'error') {
        statusVariantButton = 'error';
    } else if (orgStatusToken === 'caution' || systemsStatusToken === 'caution') {
        statusVariantButton = 'caution';
    } else if (hasUpcomingMaintenance) {
        statusVariantButton = 'info';
    } else if (hasUnreadNews) {
        statusVariantButton = 'new';
    }

    return (
        <Menu classNames={{item: classes.items}}>
            <Menu.Target>
                <NotificationsButton
                    statusVariant={statusVariantButton}
                    className={classes.button}
                    onClick={NotificationsTracking.trackClickedUserNotifications}
                />
            </Menu.Target>
            <Menu.Dropdown miw={200}>
                <Menu.Item
                    onClick={() => {
                        open();
                        if (hasUnreadNews) {
                            WhatsNewActions.markAsRead(user.additionalInformation, latestWhatsNew);
                        }
                        NotificationsTracking.trackViewedWhatsNew();
                    }}
                    rightSection={hasUnreadNews && <StatusToken variant="new" />}
                >
                    <Text>{Locales.format('NotificationsMenu.whatsNewQuestion')}</Text>
                </Menu.Item>
                <Guard canRender={!trialExpired}>
                    <Menu.Item onClick={() => navigate('/updates/list')}>
                        <Text>{Locales.format('NotificationsMenu.criticalUpdatesTitle')}</Text>
                    </Menu.Item>
                </Guard>

                <Guard canRender={canViewActivities || trialExpired}>
                    <Menu.Item onClick={() => navigate('/user/notifications')}>
                        <Text>{Locales.format('NotificationsMenu.userNotifications')}</Text>
                    </Menu.Item>
                </Guard>
                <Menu.Divider />
                <Menu.Item
                    onClick={() => {
                        NotificationsTracking.trackClickedOrganizationStatus();
                        window.open(generateDocLink({id: 1684}));
                    }}
                >
                    <Stack gap={0}>
                        <Text>{Locales.format('NotificationsMenu.organizationStatus')}</Text>
                        <Status variant={orgStatusToken} label={message} />

                        <Guard
                            canRender={
                                organizationStatus?.pauseState !== SourceStatusTypeWithTransition.RESUMING &&
                                organizationStatus?.paused &&
                                !trialExpired
                            }
                        >
                            <Anchor
                                size="xs"
                                component="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window['admin'].vent.trigger(StatusConstants.OrganizationStatusEvents.ResumeOrg);
                                }}
                            >
                                {Locales.format('NotificationsMenu.reactivate')}
                            </Anchor>
                        </Guard>
                    </Stack>
                </Menu.Item>
                <Menu.Item
                    component="a"
                    target="_blank"
                    href={STATUS_PAGE_URL[Config.env]}
                    mod={{mode: systemsStatusToken}}
                    onClick={NotificationsTracking.trackClickedSystemsStatus}
                >
                    <Stack gap={0}>
                        <Text>{Locales.format('NotificationsMenu.systemStatus')}</Text>
                        <Status
                            variant={systemsStatusToken}
                            label={Localization.convertToSentenceCase(
                                systemsStatus?.description?.toLowerCase() ||
                                    Locales.format('NotificationsMenu.noResponse'),
                            )}
                        />
                    </Stack>
                </Menu.Item>
                <Guard canRender={hasUpcomingMaintenance}>
                    <Menu.Item
                        component="a"
                        target="_blank"
                        href={STATUS_PAGE_URL[Config.env]}
                        rightSection={<StatusToken />}
                        onClick={NotificationsTracking.trackClickedScheduledMaintenance}
                    >
                        <Text>{Locales.format('NotificationsMenu.scheduledMaintenance')}</Text>
                        <Text size="xs">{DateTimeFormatter.fromNow.format(scheduledMaintenance?.schedule_for)}</Text>
                    </Menu.Item>
                </Guard>
            </Menu.Dropdown>
            <WhatsNewModal url={latestWhatsNew} isOpen={opened} onClose={close} />
        </Menu>
    );
};
