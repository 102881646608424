import {Center, Loader} from '@components/mantine';
import {FunctionComponent} from 'react';
import {InnerPageBody} from './PageBody';

export const PageLoader: FunctionComponent = () => (
    <InnerPageBody>
        <Center mih="100%" miw="100%" flex={1}>
            <Loader />
        </Center>
    </InnerPageBody>
);
