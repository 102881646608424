import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import {FormatWithResolvedOptions, MissingFormatOptions} from '../api';
import {MissingFormatWithOptions} from './internal';
import {Locales} from '../strings/Locales';

// required for `calendar` to work
dayjs.extend(calendar);
// required for `updateLocale` to work
dayjs.extend(updateLocale);
// required for ll and LLL to work
dayjs.extend(localizedFormat);

dayjs.updateLocale('en', {
    calendar: {
        sameElse(this: dayjs.Dayjs, now: dayjs.Dayjs) {
            return now.diff(this, 'months') > 6
                ? this.format(Locales.format('otherCalendarDateShort', {date: 'll'}))
                : this.format(Locales.format('otherCalendarDateLong', {date: 'll', time: 'LT'}));
        },
    },
});
export interface CalendarTimeResolvedFormatOptions {
    locale: string;
}

export class DayjsCalendarTimeFormat
    implements FormatWithResolvedOptions<dayjs.ConfigType, CalendarTimeResolvedFormatOptions>
{
    format(value: dayjs.ConfigType): string {
        return dayjs(value).calendar();
    }

    resolvedOptions(): CalendarTimeResolvedFormatOptions {
        return {locale: dayjs.locale()};
    }
}

export class DayjsCalendarTimeMissingFormat extends MissingFormatWithOptions<
    dayjs.ConfigType,
    CalendarTimeResolvedFormatOptions
> {
    constructor(options: MissingFormatOptions) {
        super(new DayjsCalendarTimeFormat(), options);
    }
}
