import {Group, GroupProps, Stack, Text, Tooltip} from '@mantine/core';
import {FunctionComponent, ReactNode} from 'react';
import {StatusToken, StatusTokenVariant} from '../status-token';
import classes from './Status.module.css';

export interface StatusProps extends GroupProps {
    variant: StatusTokenVariant;
    label: string;
    tooltip?: string;
    children?: ReactNode;
}

export const Status: FunctionComponent<StatusProps> = ({variant, label, tooltip, children, ...others}) => (
    <Group wrap="nowrap" gap="xs" align="flex-start" className={classes.root} {...others}>
        <Tooltip label={tooltip} disabled={!tooltip}>
            <StatusToken variant={variant} className={classes.token} />
        </Tooltip>
        <Stack gap={0}>
            <Text inherit span className={classes.text}>
                {label}
            </Text>
            {children}
        </Stack>
    </Group>
);
