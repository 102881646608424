import {captureException} from '@sentry/react';
import * as s from 'underscore.string';
import {LocaleEntryNotFound} from './LocaleEntryNotFound';

export class Localization {
    static convertToSentenceCase(str: string): string {
        // Regex lookbehind assertions are not supported by all browsers so instead of doing: str.split(/(?<=[.!?])\s/g)
        // We need to reverse the string and do a lookahead assertion then reverse it back in order to keep the delimiter

        const reverse = (initial: string) => initial.split('').reverse().join('');
        const sentences = reverse(str)
            .split(/\s(?=[.!?])/g)
            .map(reverse)
            .reverse();

        return sentences.map((sentence: string) => s.capitalize(sentence)).join(' ');
    }

    static reportKeyNotFound(key: string, locale = 'en') {
        try {
            captureException(
                new LocaleEntryNotFound(
                    `A string with the key "${key}" was not found while it was expected to for locale ${locale}.`,
                ),
            );
        } catch (e) {
            console.error(`Sentry could not capture the exception: ${e}`);
        }
    }
}
